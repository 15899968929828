

<template>
	<div class="tcontainer">
		<Toast />
		<div class="clearfix ttitle">
			<div class="title">
				<button type="button" class="pull-right btn btn-info" @click="loadLazyData" >
					<i class="fa fa-refresh"></i>
				</button>
				<div class="text-success pull-right" style="margin-right: 5px">
					Balance: &#8377;{{ center?.balance ? center.balance : 0 }}
				</div>
				Make Payment
			</div>
		</div>
		<div style="padding: 30px 0; background: #fff; margin-bottom: 10px; border: 1px solid #eee; border-top: 0;">
			<div class="container">
				<div class="row">
					<div class="col-sm-4"></div>
					<div class="col-sm-4" style="display: flex">
						<select v-model="pay.amount" style="margin-left: 5px" class="form-control">
							<option value="" disabled>Select Plan</option>
							<option v-for="plan in plans" :key="plan" :value="plan.price">{{ plan.plan }}</option>
						</select>
						<button @click="makeOrder" style="margin-left: 5px" type="button" class="btn btn-primary"
							:disabled="!Number(pay.amount)">Pay</button>
					</div>
					<div class="col-sm-4"></div>
					<div class="col-sm-4"></div>
					<div class="col-sm-4">
						<div class="text-center" style="padding-top: 15px; color: blue;">
							Please provide the mobile number while payment for communication and tracking transaction history
						</div>
					</div>
					<div class="col-sm-4"></div>
				</div>
			</div>
		</div>

		<div class="clearfix ttitle">
			<!-- <button @click="showAddEdit" class="pull-right btn btn-success"><i class="fa fa-plus"></i></button> -->
			<button type="button" class="pull-right btn btn-primary" @click="exportCSV($event)" >
				<i class="fa-solid fa-file-csv"></i>
			</button>
			<div class="title">Manage Center Payment History</div>
		</div>
		<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
			:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll" exportFilename="payment">


			<Column field="id" header="Id" filterField="id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['id'] }}
					</div>
				</template>
			</Column>
			<!-- <Column field="center_id" header="Center Id" filterField="center_id" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Center Id"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['center_id']}}
                    </div>
                </template>
            </Column> -->
			<Column field="amount" header="Amount" filterField="amount" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Amount" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						<b>{{ data['amount'] }}</b>
					</div>
				</template>
			</Column>
			<Column field="is_direct" header="Is Direct" filterField="is_direct" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
							<option value="">Select Is Direct</option>

							<option value="y">Y</option>
							<option value="n">N</option>
						</select>
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['is_direct'] }}
					</div>
				</template>
			</Column>
			<Column field="mode" header="Mode" filterField="mode" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Mode" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['mode'] }}
					</div>
				</template>
			</Column>
			<Column field="transaction_id" header="Transaction Id" filterField="transaction_id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Transaction Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['transaction_id'] }}
					</div>
				</template>
			</Column>
			<Column field="created_at" header="Created At" filterField="created_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Created At" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						<!-- {{data['created_at']}} -->
						<div class="tdata" style="width: 60px; margin: auto; font-weight: bold;">
							{{ format(data['created_at'], "DD/MM/YY h:mmA") }}
						</div>
					</div>
				</template>
			</Column>
			<Column header="Options">
				<template #body="{ data }">
					<div class="tdata options">
						<router-link :to="'/print-payment-receipt/'+data.id" class="btn btn-sm btn-primary" title="Download receipt">
							<i class="fa fa-print"></i>
						</router-link>
					</div>
				</template>
			</Column>
		</DataTable>


	</div>
</template>

<script>
import Toast from 'primevue/toast';
export default {
	components: {
		Toast,
	},
	data() {
		return {
			loading: false,
			totalRecords: 0,
			tableRecords: null,
			editId: 0,
			saving: false,
			lazyParams: {},
			center: null,
			plans: [],
			pay: {
				amount: '',
			},
			filters: {
				'id': { value: '', matchMode: 'like' },
				// 'center_id': {value: '', matchMode: 'like'},
				'amount': { value: '', matchMode: 'like' },
				'is_direct': { value: '', matchMode: 'like' },
				'mode': { value: '', matchMode: 'like' },
				'transaction_id': { value: '', matchMode: 'like' },
				'created_at': { value: '', matchMode: 'like' },
			},
			fdata: {
				id: '',
				center_id: '',
				amount: '',
				is_direct: 'n',
				mode: '',
				transaction_id: '',
				created_at: '',
			},
			fvalidator: {
				center_id: 'required',
				amount: 'required',
				is_direct: 'required|in:y,n',
				mode: 'required|max:255',
				transaction_id: 'required|max:255',
				created_at: 'required',
			}
		}
	},
	mounted() {
		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();
		this.get("center/center_recharge_plans").then(res => {
			this.plans = res;
		})
	},
	methods: {
		loadCenter() {
			this.get("center/load-center").then(res => {
				this.center = res;
			});
		},
		makeOrder() {
			if(this.loading) return;
			this.loading = true;

			let amount = Number(this.pay.amount);
			// amount = 1;
			this.post("center/make-order", { amount }).then(res => {
				if (res.success) {
					var options = {
						"key": "rzp_live_PEPy86oczsQcpe", // Enter the Key ID generated from the Dashboard
						"amount": amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
						"currency": "INR",
						// "name": "Acme Corp",
						// "description": "Test Transaction",
						// "image": "https://example.com/your_logo",
						"order_id": res.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
						"handler": (response) => {
							// alert("Success");
							// alert(response.razorpay_payment_id);
							// alert(response.razorpay_order_id);
							// alert(response.razorpay_signature)

							this.post("center/complete-payment", {
								order_id: response.razorpay_order_id
							}).then(res => {
								this.loading = false;
								if (res.success) {
									this.pay.amount = '';
									this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Payment received successfully', life: 3000 });
									this.loadLazyData();
								}
							})
						},
						// "prefill": {
						//     "name": "Gaurav Kumar",
						//     "email": "gaurav.kumar@example.com",
						//     "contact": "9999999999"
						// },
						// "notes": {
						//     "address": "Razorpay Corporate Office"
						// },
						"theme": {
							"color": "#3399cc"
						}
					};
					var rzp1 = new window.Razorpay(options);
					rzp1.on('payment.failed', function () {
						this.loading = false;
						this.$toast.add({ severity: 'error', summary: 'Failure', detail: 'Transaction failed', life: 3000 });
						this.loadLazyData();
					});
					rzp1.open();
				} else {
					this.loading = false;
					this.$toast.add({ severity: 'error', summary: 'Fail', detail: 'Transaction failed', life: 3000 });
					this.loadLazyData();
				}
			});
		},
		putRecord() {
			if (this.fHasError() || this.saving) return;
			this.saving = true;


			if (this.editId) this.fdata.id = this.editId;
			this.post("admin/put-center_payment_history", this.fdata).then(res => {
				this.saving = false;
				if (res.success) {
					this.addModal.hide();
					this.loadLazyData();
				}
			});
		},
		searchDoctor(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredDoctors = [...this.customers];
				} else {
					this.filteredDoctors = this.customers.filter((country) => {
						return country.name.toLowerCase().startsWith(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		loadLazyData() {
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}
			this.post("center/load-payment-history", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;
					this.tableRecords = res.rows;
				}
				this.loadCenter();
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		}
	}
}
</script>
<style scoped></style>
